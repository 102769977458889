exports.components = {
  "component---src-pages-404-de-js": () => import("./../../../src/pages/404.de.js" /* webpackChunkName: "component---src-pages-404-de-js" */),
  "component---src-pages-404-en-js": () => import("./../../../src/pages/404.en.js" /* webpackChunkName: "component---src-pages-404-en-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-de-js": () => import("./../../../src/pages/index.de.js" /* webpackChunkName: "component---src-pages-index-de-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-referents-de-js": () => import("./../../../src/pages/referents.de.js" /* webpackChunkName: "component---src-pages-referents-de-js" */),
  "component---src-pages-referents-en-js": () => import("./../../../src/pages/referents.en.js" /* webpackChunkName: "component---src-pages-referents-en-js" */),
  "component---src-pages-referents-js": () => import("./../../../src/pages/referents.js" /* webpackChunkName: "component---src-pages-referents-js" */)
}

